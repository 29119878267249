<template>
  <div class="home">
    <section class="_min-h-[780px] md:_min-h-[300px] _relative">
      <img 
          :src="require('@/assets/images/top.svg')" alt=""  
          class="
            _absolute _left-0 _right-0 _z-[1] _max-w-[1900px] 2xl:_h-auto _w-full _mx-auto md:_scale-100 _scale-[1.2] _object-cover _object-center
            2xl:_object-contain md:_-top-[80px]
            _h-[780px]
          ">
      <img :src="require('@/assets/images/city.png')" alt=""  class="_absolute _-top-[77px] lg:_bottom-auto _-left-[90px] _right-0 _z-[1] _max-w-[1900px] _w-full _mx-auto  _hidden 2xl:_block">
      <div class="_z-[3] _container _pt-[200px] _relative _flex">
        <div class="_text-left _text-[30px] _z-[2]">
          <div class="_font-bold _text-white _mb-6" v-html="Heading1"></div>
          <h1 class="_text-[40px] _text-white _font-light _font-primary _mb-6" v-html="Description1"></h1>
          <div class="_text-[40px] _text-white _font-bold _mb-6" v-html="Subdescription1"></div>
          <div class="_flex _gap-3">
            <a href="https://wa.me/081210107861" class="_btn _btn-outline _btn-accent _btn-lg _normal-case _rounded-2xl" target="_blank">
              <Icon icon="akar-icons:whatsapp-fill" width="24" height="24" class="_mr-3"/>
              {{ $t('home.button.contact-whatsapp') }}
            </a>
            <a href="#pilih-paket" class="_btn _btn-outline _btn-accent _btn-lg _normal-case _rounded-2xl" target="_blank">
              {{ $t('home.button.choose-package') }}
            </a>
          </div>
        </div>
        <div class="_ml-auto _hidden md:_block _absolute lg:_relative _-right-[20px] _bottom-0  lg:_right-auto lg:_bottom-auto">
          <img :src="require('@/assets/images/rocket.png')" alt="" class="md:_w-[350px] lg:_w-[550px] 2xl:_w-[700px]">
        </div>
      </div>
    </section>
   
    
    <section class="_pt-[150px] 2xl:_pt-[100px] lg:_min-h-0 _min-h-[600px]">
      <div class="_container">
        <div class="_flex _flex-col md:_flex-row _justify-center _gap-10 _items-center">
          <div>
            <div class="_font-extrabold _font-primary _text-[40px] _text-right _leading-[122%] _mb-4" v-html="Heading2"></div>
            <div class="_text-[20px] _text-right _font-primary _font-normal" v-html="Description2"></div>
          </div>
          <div>
            <img :src="require('@/assets/images/orang1.png')" alt="" class="_w-full xl:_w-[700px] _max-w-[400px] xl:_max-w-[1000px]">
          </div>
        </div>
      </div>
    </section>
    <section class="_relative _py-[100px] _bg-[#CB4453] sm:_bg-transparent">
      <img :src="require('@/assets/images/top2.svg')" alt=""  class="_absolute _-top-[130px] xl:_top-auto xl:_bottom-0 2xl:_-top-[130px] 2xl:_bottom-auto _left-0 _right-0 _-z-[1] _w-full _mx-auto _h-[800px] _object-cover 2xl:_object-contain 2xl:_h-auto">

      <div class="_container">
        <div class="_flex _flex-col md:_flex-row _justify-center _gap-10 _items-center">
          <div class=" 2xl:_-ml-[200px]">
            <img :src="require('@/assets/images/orang2.png')" alt="" class="_max-w-[300px] xl:_max-w-[800px] _w-full">
          </div>
          <div class="_max-w-[400px] _text-white">
            <div class="_font-extrabold _font-primary _text-[40px] _text-left _leading-[122%] _mb-4" v-html="Heading3"></div>
            <div class="_text-[20px] _text-left _font-primary _font-normal" v-html="Description3"></div>
          </div>
          
        </div>
      </div>
    </section>
    <section class="_relative _min-h-[500px] _bg-white _rounded-t-[50px] 2xl:_-mt-[100px] _py-[100px] _z-[3]" id="pilih-paket">
      <div class="_container">
        <h2 class="_text-center _text-[32px] _font-bold _font-primary _mb-20">
          Paket Internet <span class="_text-primary">ROCKNET</span>
        </h2>
        <div v-if="!paket.length && loading.paket == false">
            <div class="_text-center">
              Belum ada Paket Tersedia
            </div>
        </div>
        <div v-else-if="loading.paket == true">
            <div class="_grid _grid-cols-1 sm:_grid-cols-2 xl:_grid-cols-4 _gap-x-2 md:_gap-x-10 _font-primary">
              <div v-for="(item,id) in 4" :key="id" class="_rounded-xl _bg-gray-200 _pulse _h-[400px]"></div>
            </div>

        </div>
        <slide-scroll :ops="scrollOps" v-else >
          <div class="_grid _grid-cols-1 sm:_grid-cols-2 lg:_flex lg:_flex-nowrap _gap-x-2 md:_gap-x-10 _font-primary lg:_py-[30px]">
            <div 
              v-for="(item,id) in paket"
              :key="id"
              
              class="_relative _rounded-[18px] _bg-white _shadow-[0px_4px_11px_rgba(0,0,0,0.18)] lg:_min-w-[300px] _flex-none _mb-20">
              <div class="_rounded-[28px] _mx-auto _w-10/12 _text-center _py-5 _-mt-[30px]" :class="item.color">
                  <div class="_font-primary _font-bold _text-[18px] _uppercase">
                    {{item.name}}
                  </div>                
              </div>
              <div class="_py-10 _text-center">
                <div class="_text-sm _mb-5">
                  Up To
                </div>
                <div class="_text-[40px] _font-bold _mb-5">
                  {{item.speed}}
                </div>
                <div>
                  <div>Unlimited kuota</div>
                  <div>Fiber Connection</div>
                </div>
              </div>
              <div class="_bg-[#F1F1F1] _px-5 _py-10 _text-center _font-bold _rounded-b-[18px]">
                  <div class=" _flex _text-primary _items-end _justify-center _mb-5">

                      <div class="_text-xs">Rp</div>
                      <div class="_text-[48px] _leading-[38px]">{{item.price}}</div>
                      <div class="_text-xs" v-html="`/${$t('package.cards.price.month')}`"></div>
                  </div>
                  <div class="_text-xs" v-html="`*${$t('package.cards.price.tax')}`"></div>
                  <a href="https://wa.me/081210107861" class="_absolute _-bottom-[25px] _left-0 _right-0 _btn _btn-primary _mx-auto _h-[55px] _w-9/12 _rounded-full _text-white _normal-case" v-html="`${$t('package.cards.price.button')}`"></a>
              </div>
            </div>
          </div>
        </slide-scroll>
        <div class="lg:_py-16 _text-center _font-bold _text-[40px] _text-red-500">
          {{ $t('package.cards.disc.title') }} 50% {{ $t('package.cards.disc.sub') }}
        </div>
      </div>
      
    </section>
    <!-- <section class=" _py-10 _bg-[url('@/assets/images/bgceklokasi.png')]">
      <div class="container">
        <div class="_grid _grid-cols-12 _gap-5 _items-center">
          <div class="_col-span-5 ">
            <img src="@/assets/images/ceklokasi.svg" alt="" class="_ml-auto">
          </div>
          <div class="_col-span-7 _text-left ">
            <h5 class="_font-bold _text-black _text-[32px] _mb-5">Cek Lokasi Area</h5>
            <p class="_text-[20px] _mb-5">Pastikan wilayah anda sudah kami cover <br/> dengan jaringan kami.</p>
            <router-link to="/ceklokasi" class="_btn _btn-primary _text-white">Cek Cakupan Area</router-link>
          </div>
        </div>
      </div>

    </section> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { getHomeStatic } from '@/api/strapi';
import { getPaket } from '@/api/backend';
import vuescroll from "vuescroll";
import i18n from '@/i18n';
export default {
  name: 'HomeView',
  components: {
    slideScroll: vuescroll
  },
  data: ()=>({
    meta: i18n.messages[i18n.locale].home.meta,  

    bhs: i18n.locale,
    scrollOps: {
      vuescroll: {
         mode: 'slide',
        sizeStrategy: 'percent',
        detectResize: true,
        /** Enable locking to the main axis if user moves only slightly on one of them at start */
        locking: true,
        zooming: false,
      },
      scrollPanel: {
        scrollingY: false,
      },
      rail: {
      },
      bar: {
        background: '#30318B',

      },
    },
    loading: {
      paket: true,
    },
    Heading1: `ROCKNET`,
    Description1: `Solusi <strong class="_font-bold">Internet Cepat</strong> <br> Untuk Rumah Anda`,
    Subdescription1: `100% Fiber Bebas Kuota`,
    Heading2: `Koneksi Penuh <br/> Tanpa Kuota`,
    Description2: `ROCKNET menawarkan koneksi penuh <br/> tanpa batasan kuota dengan kecepatan maksimal`,
    Heading3: `Gaming Terus <br/> Livestream Mulus`,
    Description3: `ROCKNET memiliki berbagai paket <strong class="_font-bold">Super Cepat</strong> yang cocok untuk gamers dan konten kreator dengan harga yang terjangkau`,
    
    paket: [
      // {
      //   speed: 15 ,
      //   price: 185,
      //   color: '_bg-warning',
      // },
      // {
      //   speed: 25,
      //   price: 240 ,
      //   color: '_bg-orange-400',
      // },
      // {
      //   speed: 30,
      //   price: 285,
      //   color: '_bg-success',
      // },
      // {
      //   speed: 50 ,
      //   price: 420 ,
      //   color: '_bg-info',
      // },
    ]
  }),
  computed: {
  },
  beforeMount() {
    this.setHomeStatic()
  },
  metaInfo() {
    return this.meta
  },
  methods: {
    async setHomeStatic() {
      getHomeStatic({
        locale: this.bhs
      }).then((res)=>{
        if(res) {
          const {data} = res
          if(res.status === 200) {
            const val = data.data.attributes
            // this.homeStatic = data.data
            // console.log(this.homeStatic)
            this.Heading1 = val.Heading1
            this.Description1 = val.Description1
            this.Subdescription1 = val.Subdescription1
            this.Heading2 = val.Heading2
            this.Description2 = val.Description2
            this.Heading3 = val.Heading3
            this.Description3 = val.Description3
            console.log('success load data')
          } else {
            console.log('failed to load data')
            
          }
          
          console.log(res)
        }
      })
    },
    async loadPaket() {
      if(this.paket && this.paket.length) {
        this.loading.paket = true
      }
      await getPaket().then((res) => {
        if(res) {
          const {data} = res
          if(res.status == 200) {
            console.log('berhasil')
            console.log(data)
            data.data.forEach(item => {
              if(item.package_type == 'product') {
                this.paket.push({
                  name: item.package_name,
                  speed: item.package_speed,
                  price: this.priceFormat(item.package_price),
                  color: this.setColor(item.package_code)
                })
              }
            });
            console.log(this.paket)
            
          } else {
            console.log('gagal')
            console.log(res)
          }
          this.loading.paket = false

        }
      })
    },
    setColor(pk_code) {
      {
        let color;
        switch (pk_code) {
          case 'R15':
            color = '_bg-warning'
            break;
                  
          case 'R25':
            color = '_bg-orange-400'
            break;
                  
          case 'R30':
            color = '_bg-success'
            break;
                  
          case 'R50':
            color = '_bg-warning'
            break;
                  
          default:
            color = '_bg-info'
            break;
          }

        return color
      }
    },
    priceFormat(number) {
      const numformat = new Intl.NumberFormat('en', { style: 'decimal', currency: 'IDR', notation: 'compact', compactDisplay: 'short' }).format(number)
      return numformat
    },
  },
  mounted() {
    this.loadPaket()
    console.log(this.meta)
  }
}
</script>
