import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
// import RouterComponent from '@/components/RouteComponent.vue'
import i18n from "@/i18n";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`,
  },
  {
    path: "/:lang/",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: HomeView,
      },

      {
        path: "information/404",
        name: "InformasiNotFound",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Informasi/NotFound.vue"
          ),
      },
      {
        path: "privacy",
        name: "PrivacyPolicy",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Informasi/PrivacyPolicy.vue"
          ),
      },
      {
        path: "terms-condition",
        name: "TermsCondition",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Informasi/TermsCondition.vue"
          ),
      },
      {
        path: "refund-policy",
        name: "RefundPolicy",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Informasi/RefundPolicy.vue"
          ),
      },
      {
        path: "delivery-policy",
        name: "DeliveryPolicy",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Informasi/DeliveryPolicy.vue"
          ),
      },

      {
        path: "information/:category?",
        name: "InformasiIndex",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Informasi/IndexView.vue"
          ),
      },
      {
        path: "information/:category/:slug",
        name: "InformasiDetail",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Informasi/DetailView.vue"
          ),
      },

      {
        path: "help",
        name: "Bantuan",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Bantuan/IndexView.vue"
          ),
      },
      {
        path: "package",
        name: "Paket",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Paket/IndexView.vue"
          ),
      },
      {
        path: "ceklokasi",
        name: "CekLokasi",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/CekLokasi/IndexView.vue"
          ),
      },
      {
        path: "subscribe",
        name: "Berlangganan",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Berlangganan/IndexView.vue"
          ),
      },
      {
        path: "faq/:category?",
        name: "FAQ",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/FAQ/IndexView.vue"),
      },
      {
        path: "about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
